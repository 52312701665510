import { NgModule } from '@angular/core';
import { TenantRepository } from '@repository/tenant/tenant.repository';
import { ApiModule } from '@api/api.module';
import { AuthenticationRepository } from '@repository/authentication/authentication.repository';
import { SmartChargingRepositoryService } from '@repository/smart-charging/smart-charging.repository.service';
import { TransactionRepositoryService } from '@repository/transaction/transaction.repository.service';
import { SitesRepositoryService } from '@repository/sites/sites.repository.service';
import { CompanyRepositoryService } from '@repository/company/company.repository.service';
import { ReservationRepositoryService } from '@repository/reservation/reservation.repository.service';
import { SuperTenantRepository } from '@repository/tenant/super-tenant.repository';
import { AssetsRepositoryService } from '@repository/assets/assets.repository.service';
import { TagRepositoryService } from './tags/tag.repository.service';
import { ChargingStationRepository } from './charging-station/charging-station.repository';
import { EnvironmentRepositoryService } from './config/environment.repository.service';
import { OcpiRepositoryService } from './ocpi/ocpi.repository';
import { SiteAreaRepositoryService } from './site-area/site-area.repository.service';

@NgModule({
  imports: [ApiModule],
  declarations: [],
  providers: [
    AssetsRepositoryService,
    TagRepositoryService,
    ChargingStationRepository,
    EnvironmentRepositoryService,
    OcpiRepositoryService,
    SiteAreaRepositoryService,
    TenantRepository,
    SuperTenantRepository,
    AuthenticationRepository,
    SmartChargingRepositoryService,
    TransactionRepositoryService,
    SitesRepositoryService,
    CompanyRepositoryService,
    ReservationRepositoryService,
  ],
  exports: [],
})
export class RepositoryModule {}
