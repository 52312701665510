<div class="sidebar">
  <div class="logo">
    <div class="simple-text logo-mini">
      <a
        mat-button
        (click)="toggleSidebar()"
        href="javascript:void(0)"
      >
        <i class="material-icons">menu</i>
      </a>
    </div>
    @if (!sidebarMinimized) {
      <div class="simple-text ms-3 me-2">
        <a
          routerLink="/release-notes"
          class="app-version"
        >
          {{ 'general.app_name' | translate }}
        </a>
      </div>
    }
  </div>
  <div class="logo text-center p-0">
    <div class="simple-text">
      <img
        class="logo-img"
        [class.small-logo-img]="sidebarMinimized"
        [src]="logo"
        alt=""
      />
    </div>
  </div>
  <div class="sidebar-wrapper">
    <div class="user">
      <div class="user-info">
        <a
          class="user-info-container"
          data-bs-target="#collapseDetails"
          data-bs-toggle="collapse"
        >
          <img
            class="photo"
            [src]="loggedUserImage"
          />

          <span class="username">
            <span>
              {{ loggedUser?.name }}
            </span>
            <span>
              {{ loggedUser?.firstName }}
            </span>
          </span>
          <!-- Spacer used to place the arrow to the right -->
          <span class="flex-grow-1"></span>
          <mat-icon>arrow_drop_down</mat-icon>
        </a>

        <div
          class="collapse"
          id="collapseDetails"
        >
          <ul class="nav">
            @if (canEditProfile) {
              <li
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="nav-item"
              >
                <a
                  [routerLink]="['/user/profile']"
                  class="nav-link"
                >
                  <span class="sidebar-mini"><i class="material-icons sidebar-mini-icons">portrait</i></span>
                  <span class="sidebar-normal">{{ 'users.profile' | translate }}</span>
                </a>
              </li>
            }
            <li
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              class="nav-item"
            >
              <a
                href="javascript:void(0)"
                (click)="logout()"
                class="nav-link"
              >
                <span class="sidebar-mini"><i class="material-icons sidebar-mini-icons">lock</i></span>
                <span class="sidebar-normal">{{ 'authentication.sign_out' | translate }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <ul class="nav nav-content">
      @for (menuItem of menuItems; track menuItem.title) {
        <li
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: false }"
          class="nav-item"
        >
          <a
            [routerLink]="[menuItem.path]"
            class="nav-link"
          >
            <i class="material-icons">{{ menuItem.icon }}</i>
            <p>{{ 'general.menu.' + menuItem.title | translate }}</p>
          </a>
        </li>
      }

      @if (isSiteAdmin || isAdmin) {
        <li class="nav-item">
          <a
            href="https://help.vev.com/knowledge"
            target="_blank"
            class="nav-link"
          >
            <i class="material-icons">help</i>
            <p>{{ 'general.menu.help' | translate }}</p>
          </a>
        </li>
      }
    </ul>
  </div>
</div>
