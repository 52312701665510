import { NgModule } from '@angular/core';
import { TenantApiService } from '@api/tenant/tenant.api.service';
import { AuthenticationApiService } from '@api/authentication/authentication.api.service';
import { SmartChargingApiService } from '@api/smart-charging/smart-charging.api.service';
import { TransactionApiService } from '@api/transaction/transaction.api.service';
import { SiteApiService } from '@api/site/site.api.service';
import { CompanyApiService } from '@api/company/company.api.service';
import { ReservationApiService } from '@api/reservation/reservation.api.service';
import { AssetsApiService } from '@api/assets/assets.api.service';
import { TagApiService } from './tag/tag.api.service';
import { ChargingStationApiService } from './charging-station/charging-station.api.service';
import { EnvironmentApiService } from './config/environment-api.service';
import { OcpiApiService } from './ocpi/ocpi.api';
import { SiteAreaApiService } from './site-area/site-area.api.service';
import { EmbeddedPowerBiApiService } from './embedded-powerbi/embedded-powerbi.api.service';

@NgModule({
  imports: [],
  declarations: [],
  providers: [
    TagApiService,
    ChargingStationApiService,
    EnvironmentApiService,
    OcpiApiService,
    SiteAreaApiService,
    TenantApiService,
    AuthenticationApiService,
    SmartChargingApiService,
    TransactionApiService,
    EmbeddedPowerBiApiService,
    SiteApiService,
    CompanyApiService,
    ReservationApiService,
    AssetsApiService,
  ],
  exports: [],
})
export class ApiModule {}
