import { Command } from '../../../../../../../api/charging-station/dto/charging-station.dto';

export interface Card {
  name: Command;
  title: string;
  fields: Field[];
}

export enum CommandExecutionState {
  Idle,
  ExecutingCommand,
  Timeout,
  NotSupported,
  Success,
  Error,
  Pending,
  NotFound,
  RebootRequired,
  Scheduled,
  Unknown,
}

export enum FieldTypes {
  SELECT = 'select',
  NUMBER = 'number',
  TEXT = 'text',
  DATE = 'date',
  FILE = 'file',
  LIST = 'list',
  GROUP = 'group',
  RFID = 'rfid',
}

export enum CommonStatusCode {
  SUCCESS = 200, // OK
  ERROR = 500, // Internal Server Error
  PENDING = 102, // Processing
  NOT_FOUND = 404, // Not Found
  NOT_SUPPORTED = 501, // Not Implemented
  REBOOT_REQUIRED = 503, // Service Unavailable
  SCHEDULED = 202, // Accepted
  UNKNOWN = 520, // Unknown Error (non standard)
}

export interface Field {
  name: string;
  label: string;
  type: FieldTypes;
  options?: FieldOption[];
  fields?: Field[];
  itemFields?: Field[];
  optional?: boolean;
  autoId?: boolean;
  defaultValue?: string | number;
}

export interface FieldOption {
  value: string | number;
  label: string;
}

// Create an object map for status codes to states
const statusCodeStateMap: Record<number, CommandExecutionState> = {
  [CommonStatusCode.SUCCESS]: CommandExecutionState.Success,
  [CommonStatusCode.ERROR]: CommandExecutionState.Error,
  [CommonStatusCode.PENDING]: CommandExecutionState.Pending,
  [CommonStatusCode.NOT_FOUND]: CommandExecutionState.NotFound,
  [CommonStatusCode.NOT_SUPPORTED]: CommandExecutionState.NotSupported,
  [CommonStatusCode.REBOOT_REQUIRED]: CommandExecutionState.RebootRequired,
  [CommonStatusCode.SCHEDULED]: CommandExecutionState.Scheduled,
  [CommonStatusCode.UNKNOWN]: CommandExecutionState.Unknown,
};

// Map function using the object map
export function mapStatusCodeToState(statusCode: number): CommandExecutionState {
  return statusCodeStateMap[statusCode] || CommandExecutionState.Unknown;
}

export enum HashAlgorithmEnumType {
  SHA256 = 'SHA256',
  SHA384 = 'SHA384',
  SHA512 = 'SHA512',
}

export enum LogEnumType {
  DIAGNOSTICS_LOG = 'DiagnosticsLog',
  SECURITY_LOG = 'SecurityLog',
}

export enum CertificateUseEnumType {
  CENTRAL_SYSTEM_ROOT_CERTIFICATE = 'CentralSystemRootCertificate',
  MANUFACTURER_ROOT_CERTIFICATE = 'ManufacturerRootCertificate',
}

export enum UpdateType {
  Differential = 'Differential',
  Full = 'Full',
}

export enum AuthorizationStatus {
  ACCEPTED = 'Accepted',
  BLOCKED = 'Blocked',
  EXPIRED = 'Expired',
  INVALID = 'Invalid',
  CONCURRENT_TX = 'ConcurrentTx',
}

export enum MessageTrigger {
  BOOT_NOTIFICATION = 'BootNotification',
  HEARTBEAT = 'Heartbeat',
  STATUS_NOTIFICATION = 'StatusNotification',
  METER_VALUES = 'MeterValues',
  DIAGNOSTICS_STATUS_NOTIFICATION = 'DiagnosticsStatusNotification',
  FIRMWARE_STATUS_NOTIFICATION = 'FirmwareStatusNotification',
}
