import { CancelReservationDto, ConnectorReservationDto } from '@api/charging-station/dto/connector-reservation.dto';
import { ChangeAvailabilityDto } from '@api/charging-station/dto/change-availability.dto';
import { ClearChargingProfilesRequest } from '@api/charging-station/dto/clear-charging-profile.dto';
import { ActionResponse } from '../../../types/DataResult';
import {
  CertificateHashData,
  DeleteCertificateCommandParam,
  GetDiagnosticsCommandParam,
  GetInstalledCertificatesIdsCommandParam,
  GetLogCommandParam,
  InstallCertificateCommandParam,
  SendLocalListCommandParam,
  TriggerMessageCommandParam,
  UpdateFirmwareCommandParam,
} from '../../../types/ocpp/OCPP';

export interface GetLogsFileResponse extends ActionResponse {
  status: string;
  fileName: string;
}

export enum Command {
  GET_LOG = 'GetLog',
  RESERVE_CONNECTOR = 'ReserveNow',
  CANCEL_RESERVATION = 'CancelReservation',
  CHANGE_AVAILABILITY = 'ChangeAvailability',
  CLEAR_CHARGING_PROFILES = 'ClearChargingProfiles',
  GET_DIAGNOSTICS = 'GetDiagnostics',
  UPDATE_FIRMWARE = 'UpdateFirmware',
  DELETE_CERTIFICATE = 'DeleteCertificate',
  GET_INSTALLED_CERTIFICATE_IDS = 'GetInstalledCertificateIds',
  INSTALL_CERTIFICATE = 'InstallCertificate',
  SEND_LOCAL_LIST = 'SendLocalList',
  GET_LOCAL_LIST_VERSION = 'GetLocalListVersion',
  TRIGGER_MESSAGE = 'TriggerMessage',
  SECURE_FIRMWARE_UPDATE = 'SecureFirmwareUpdate',
}

export type CommandBodyType =
  | GetDiagnosticsCommandParam
  | GetLogCommandParam
  | UpdateFirmwareCommandParam
  | DeleteCertificateCommandParam
  | GetInstalledCertificatesIdsCommandParam
  | CertificateHashData
  | InstallCertificateCommandParam
  | SendLocalListCommandParam
  | TriggerMessageCommandParam
  | CancelReservationDto
  | ChangeAvailabilityDto
  | ClearChargingProfilesRequest
  | ConnectorReservationDto;
