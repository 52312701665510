import { forkJoin, Observable, tap } from 'rxjs';
import { APP_INITIALIZER, Provider } from '@angular/core';
import { AuthClientConfig } from '@auth0/auth0-angular';
import { LocaleService } from '@services/locale.service';
import { CentralServerService } from '../services/central-server.service';
import { EnvironmentService } from '../services/environment.service';
import { MixpanelService } from '../services/mixpanel.service';

const appFactory =
  (
    centralServerService: CentralServerService,
    environmentService: EnvironmentService,
    mixpanelService: MixpanelService,
    authConfig: AuthClientConfig,
    localeService: LocaleService,
  ): (() => Observable<any>) =>
  () =>
    forkJoin([
      environmentService.init().pipe(
        tap((config) => {
          mixpanelService.init();
          authConfig.set(config.auth0Config);
        }),
      ),
      new Observable((observer) => {
        centralServerService.initUserToken();
        localeService.initLocale();
        observer.next();
        observer.complete();
      }),
    ]);

export const appInitProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: appFactory,
  deps: [CentralServerService, EnvironmentService, MixpanelService, AuthClientConfig, LocaleService],
  multi: true,
};
